<template>
<div>
  <div>
    <Social />
    <navbar :items='about' />
    <main>
      <router-view />
    </main>
    <Footer :items='about' />
  </div>
</div>
</template>

<script>
import Footer from '~/components/core/footer.vue'
import Navbar from '~/components/core/navbar.vue'
import { socket } from '~/plugins/socket.client.js'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      currentUser: {},
      about: [],
    }
  },
  async fetch () {
    const _ = await this.$store
      .dispatch('getAbout', {
        locale: this.$i18n.locale,
        fields: 'address,description,email,phone,title'
      })
    this.about = _;
    console.log(_, 'getpages')
      // .then(res => {
      //   console.log("getPages ===>>>>", res)
      //   this.about = res
      // })
      // .catch((error) => {
      //   this.$sentry.captureException(error)
      // })
  },
  created() {
    if (!process.client) {
      return
    }
  },
  mounted() {
    this.$i18n.messages['fr'] = {}
    socket.on('joined', (res) => {
    })
    socket.on('left', (res) => {
    })
    socket.on('joinedRoom', (res) => {
    })
    socket.on('leftRoom', (res) => {
    })
    socket.on('message', (res) => {
      if (res.type === 'chat') {
        this.getMessages()
      } else if (res.type === 'room') {
        this.getRooms(res.data)
      }
    })
    socket.on('finishedChat', (res) => {
      this.$store.dispatch('finishedChatId', res)
    })
    this.$bridge.$on('join_room', (message) => {
      this.joinToRoom(message)
    })
    this.$bridge.$on('join_chat', (message) => {
      this.joinToChat(message)
    })
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn
    }),
  },
  methods: {
    joinToChat(message) {
      socket.emit(
        'join',
        {
          username: message.username,
          user_id: message.user_id,
        }
      )
    },
    joinToRoom(message) {
      socket.emit(
        'joinRoom',
        {
          username: message.username,
          room: message.room,
        },
        ({ res, rej }) => {
          if (res) {
            if (this.$route.query.room_id !== 'new') {
              this.getMessages()
            }
          } else {
            console.error(rej)
          }
        }
      )
    },
    getRooms(room) {
      if (room.id === parseInt(this.$route.query.room_id)) {
        this.$bridge.$emit('room_changed')
      }
      if (this.$auth.user.role.id === 4) {
        this.$store
          .dispatch('getChatrooms', {
            populate: '*',
            'filters[$or][0][consultant][id]': this.$auth.user.id,
            'filters[$and][0][isCompleted][$ne]': true,
            'sort[0][createdAt]': 'DESC',
          })
          .then((res) => {
            this.$store.dispatch('setActiveRooms', res)
          })
      } else {
        this.$store
          .dispatch('getChatrooms', {
            populate: '*',
            'filters[$or][0][user][id]': this.$auth.user.id,
            // 'filters[$and][0][isCompleted][$ne]': true,
            'filters[$and][0][rate][$null]': true,
            'sort[0][createdAt]': 'DESC',
          })
          .then((res) => {
            this.$store.dispatch('setActiveRooms', res)
          })
      }
    },
    getMessages() {
      this.$store
        .dispatch('getChatmessages', {
          populate: '*',
          'filters[$and][0][chatroom][id]': this.$route.query.room_id,
        })
        .then((res) => {
          this.$store.dispatch('setMessage', res)
        })
    },
  },
}
</script>
