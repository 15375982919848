var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("Social"),
        _vm._v(" "),
        _c("navbar", { attrs: { items: _vm.about } }),
        _vm._v(" "),
        _c("main", [_c("router-view")], 1),
        _vm._v(" "),
        _c("Footer", { attrs: { items: _vm.about } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }